import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ProductCard = ({ name, image, features, onlyTextFeatures, link }) => {
  return (
    <div className="flex h-full flex-col items-center px-4 pb-8 md:px-8 md:pb-12">
      <GatsbyImage
        image={getImage(image.directus_files_id.imageFile)}
        alt={image.directus_files_id.title}
        className="mx-auto"
      />

      <h6 className="mb-2 max-w-[368px] text-center font-bold md:mb-4 md:text-xl">{name}</h6>

      {features ? (
        <div className="mb-6 flex items-center justify-between divide-x divide-gray-500">
          {features.map((feature) => {
            const imageSrc = feature.image_small
              ? feature.image_small.imageFile?.publicURL
              : feature.image?.imageFile?.publicURL;

            if (!imageSrc) {
              return null;
            }

            return <img key={feature.name} src={imageSrc} alt={feature.name} className="h-5 px-1" />;
          })}
        </div>
      ) : null}

      {onlyTextFeatures ? (
        <div className="mb-6 flex items-center justify-between">
          {onlyTextFeatures.map((feature) => (
            <span key={feature} className="mx-1 font-light text-[#697276] after:content-['_|'] last:after:content-none">
              {feature}
            </span>
          ))}
        </div>
      ) : null}

      <Link
        to={link}
        className="mt-auto flex h-12 w-full items-center justify-center rounded-full bg-[#303030]  text-white hover:bg-[#00AAA6]"
      >
        Conoce más
      </Link>
    </div>
  );
};

export default ProductCard;
